import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Event, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { SidebarService } from './../sidebar/sidebar.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Http, Response, Headers, URLSearchParams, RequestOptions } from '@angular/http';
import { environment } from './../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {

  private basePathUrl = environment.baseAuth;
  public validateRouteFrontBackUrl = this.basePathUrl + '/validateRouteFrontBack';

  private currentUser: string;
  private currentToken: string;
  private currentUrl: string;
  private iidRH : string;
  private control = 0;

  constructor(
    private http: Http
    , private side: SidebarService
    , private router: Router
  ) {
    this.currentUser = Cookie.get('iuser');
    this.currentToken = Cookie.get('itoken');
    this.iidRH ="";
    this.iidRH = Cookie.get('iidRH');
  }

   /**
   *
   * Utilizado para validar se o usuário tem permissão para execução da rota
   * Deverá ser executado no construtor de cada página evitando
   * redirecionamentos de páginas sem permissão.
   * Deverá também ser executado antes de cada chamada de método
   * para validação de permissão do usuário ao método (para além da página)
   * Criado por: Thiago Andrade em 28/09/2020
   */
  validateRouteFrontBack(usern: string, token: string, url: string) : Promise<any>{

    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');

    const options = new RequestOptions({ headers: headers });
    const payload = '{"username": "' + usern + '", "token": "' + token + '","route":"' + url + '"}';
    return this.http
      .post(this.validateRouteFrontBackUrl, payload, options)
      .toPromise();

  }

  canActivate() {

    if (Cookie.get('iuser') != '') {

      this.currentUrl = this.router.url;

      this.router.events.subscribe((data: Event) => {
        if (data instanceof NavigationEnd) {
          this.currentUrl = data.url;
        }
      });
      console.log("teste"+this.iidRH);

      if (this.iidRH == null ) {
        window.location.href = 'https://intranet.inesctec.pt/logout';
        return false;
      }else{
      this.side.getValidateToken(this.currentUser, this.currentToken).then(data => {
        //console.log("entrou" + String(data));
        if (String(data) === 'true') {
          //console.log("teste" + this.currentUrl.split("?")[0]);
          if (this.currentUrl.split("?")[0]) {
            //console.log("teste" + this.currentUrl.split("?")[1]);
            if (this.currentUrl.split("?")[1]) {
              //console.log("teste que nao devia entrar");
              if (this.currentUrl.split("?")[1].split("=")[0] == "projectId") {

                  this.side.getPermissionsNewWithParameters(this.currentUser, this.currentToken, this.currentUrl).then(datas => {

                    let body = JSON.parse(sessionStorage.getItem('rota'));

                    if (this.currentUrl === "/dashboard/home" || this.currentUrl === "/dashboard") {
                      this.router.navigate(['/dashboard/home']);
                    } else {
                      if (datas.length != 2) {
                        this.control = 1;
                      } else {
                        this.side.getPermissionsNew(this.currentUser, this.currentToken, this.currentUrl.split("?")[0]).then(datas => {

                          let body = JSON.parse(sessionStorage.getItem('rota'));

                          if (this.currentUrl === "/dashboard/home" || this.currentUrl === "/dashboard") {
                            this.router.navigate(['/dashboard/home']);
                          } else {
                            if (datas == true) {
                              this.control = 1;
                            } else {
                              this.router.navigate(['/dashboard/home']);
                            }
                          }
                        });
                        this.router.navigate(['/dashboard/home']);
                      }
                    }
                  });
              }

            } else {

              this.side.getPermissionsNew(this.currentUser, this.currentToken, this.currentUrl.split("?")[0]).then(datas => {

                if (this.currentUrl === "/dashboard/home" || this.currentUrl === "/dashboard") {
                  this.router.navigate(['/dashboard/home']);
                } else {
                  if (datas == true) {
                    this.control = 1;
                  } else {
                    this.router.navigate(['/dashboard/home']);
                  }
                }
              });

            }

          } else {

            this.side.getPermissionsNew(this.currentUser, this.currentToken, this.currentUrl.split("?")[0]).then(datas => {

              let body = JSON.parse(sessionStorage.getItem('rota'));

              if (this.currentUrl === "/dashboard/home" || this.currentUrl === "/dashboard") {
                this.router.navigate(['/dashboard/home']);
              } else {
                if (datas == true) {
                  this.control = 1;
                } else {
                  this.router.navigate(['/dashboard/home']);
                }
              }
            });

          }
        } else {
          window.location.href = environment.authLoginForm;
          return false;
        }
      });

    }
    }

    return true;
    // Se o utilizador nao tiver login efetuado, é redireccionado para a pagina de login
  }

}
