import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Notification, APICall, APIParams} from './notification';

import {Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import {Cookie} from 'ng2-cookies/ng2-cookies';
import {Observable} from 'rxjs/Observable';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

@Injectable()
export class NotificationService {
  private currentUserName = Cookie.get('username'); // JSON.parse(sessionStorage.getItem('currentUser')).username;
  private currentUserToken = Cookie.get('itoken'); // JSON.parse(sessionStorage.getItem('currentUser')).token;
  private currentUser = Cookie.get('iidRH'); // JSON.parse(sessionStorage.getItem('currentUser')).idRH;


  private basePathUrl = 'https://iris.inesctec.pt/authAPI/apiRequest';
  private apiName = 'notificationAPI';
  private activeNotificationsUrl = 'getActiveNotifications';
  private createNotificationsUrl = 'insertNotification';
  private updateNotificationsUrl = 'updateNotification';

  private notificationsArray: Notification[] = new Array<Notification>();
  private notifications = new BehaviorSubject(new Array<Notification>());
  private notificationsUnread = new BehaviorSubject(0);
  currentNotifications = this.notifications.asObservable();
  currentUnreadNotifications = this.notificationsUnread.asObservable();

  constructor(private http: Http) {

        if (Cookie.get('username') !== '') {
          this.currentUser = Cookie.get('username');
      } else {

        this.currentUser = Cookie.get('iuser');

      }


    this.getAllNotifications();
  }

  getAllNotifications(): void {
    this.getAllActiveNotificationsService()
      .subscribe(
        notifications => {
          for (let i = 0; i < notifications.length; i++) {
            let found = false;
            for (let j = 0; j < this.notificationsArray.length; j++) {
              if (this.notificationsArray[j].idNotification === notifications[i].idNotification) {
                found = true;
                break;
              }
            }
            if (!found) {
              this.addNotification(notifications[i]);
            }
          }
        },
        error => {
        });
  }

  createNotification(notification: Notification): void {
    this.createNotificationService(notification)
      .subscribe(
        notificationCreated => {
          if (notificationCreated) {
            this.getAllNotifications();
          }
        },
        error => {
        });
  }

  updateNotification(notifications: Notification[]): void {
    this.updateNotificationService(notifications)
      .subscribe(
        notificationUpdated => {
          if (notificationUpdated) {
            this.setNotifications(notifications);
          }
        },
        error => {
        });
  }

  inativateNotification(notifications: Notification[]): void {
    this.updateNotificationService(notifications)
      .subscribe(
        notificationUpdated => {
          if (notificationUpdated) {
            const updatedNotifications: Notification[] = new Array<Notification>();
            let index = 0;
            for (let i = 0; i < notifications.length; i++) {
              if (notifications[i].active !== false) {
                updatedNotifications[index] = notifications[i];
                index++;
              }
            }
            this.setNotifications(updatedNotifications);
          }
        },
        error => {
        });
  }

  getAllActiveNotificationsService(): Observable<Notification[]> {
    const apiCall: APICall = new APICall();
    const params: APIParams = new APIParams();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'GET';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.activeNotificationsUrl;
    apiCall.params = params;
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({headers: headers});
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractNotificationListData)
      .catch(this.handleError);
  }

  createNotificationService(notification: Notification): Observable<boolean> {
    const apiCall: APICall = new APICall();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'POST';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.createNotificationsUrl;
    apiCall.body = [notification];
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({headers: headers});
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractCreateData)
      .catch(this.handleError);
  }

  updateNotificationService(notifications: Notification[]): Observable<boolean> {
    const apiCall: APICall = new APICall();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'PUT';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.updateNotificationsUrl;
    apiCall.body = notifications;
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({headers: headers});
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractCreateData)
      .catch(this.handleError);
  }

  private extractNotificationListData(res: Response) {
    const body = res.json();
    const notifications: Notification[] = new Array<Notification>();
    for (let i = 0; i < body.length; i++) {
      const notification = new Notification;
      notification.idNotification = body[i].idNotification;
      if (body[i].createDate !== null) {
        notification.createDate = body[i].createDate
      }
      if (body[i].modifyDate !== null) {
        notification.modifyDate = body[i].modifyDate
      }
      notification.link = body[i].link;
      notification.username = body[i].username;
      notification.idRH = body[i].idRH;
      notification.icon = body[i].icon;
      notification.title = body[i].title;
      notification.readNotification = body[i].readNotification;
      notification.message = body[i].message;
      notification.active = body[i].active;
      notification.createUser = body[i].createUser;
      notification.modifyUser = body[i].modifyUser;
      notifications[i] = notification;

    }
    return notifications;
  }

  private extractCreateData(res: Response) {
    let result = false;
    if (res !== null) {
      const body = res.json();
      if (body !== null) {
        result = body;
      }
    }
    return result;
  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.err || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    console.error(errMsg);
    return Observable.throw(errMsg);
  }

  addNotification(notification: Notification) {
    if (notification.username === this.currentUserName) {
      this.notificationsArray.push(notification);
      this.notifications.next(this.notificationsArray);
      this.notificationsUnread.next(this.getNotificationsUnRead());
    }
  }

  getNotifications() {
    return this.notificationsArray;
  }

  setNotifications(notifications: Notification[]) {
    this.notificationsArray = notifications;
    this.notifications.next(this.notificationsArray);
    this.notificationsUnread.next(this.getNotificationsUnRead());
  }

  getNotificationsUnRead() {
    const countUnread: number = this.notificationsArray.filter(notification => notification.readNotification === false).length;
    return countUnread;
  }

  removeNotification(notification: Notification) {
    const index: number = this.notificationsArray.indexOf(notification);
    if (index !== -1) {
      this.notificationsArray.splice(index, 1);
    }
    this.notifications.next(this.notificationsArray);
    this.notificationsUnread.next(this.getNotificationsUnRead());
  }

}
