import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Headers, Http, Response} from '@angular/http';
import {SidebarService} from '../sidebar/sidebar.service';
import {Cookie} from 'ng2-cookies/ng2-cookies';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import { environment } from './../../../environments/environment';
@Injectable()
export class AuthService {

  public token: string;
  public idRH: string;
  public rota: string;
  public profName: string;
  public lstGroups: string;
  //public lstEstruturas: string;

  constructor(private http: Http, private side: SidebarService) {
    // set token if saved in local storage
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  b64EncodeUnicode(str: string): string {
    if (window
      && 'btoa' in window
      && 'encodeURIComponent' in window) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(('0x' + p1) as any);
      }));
    } else {
      console.warn('b64EncodeUnicode requirements: window.btoa and window.encodeURIComponent functions');
      return null;
    }

  }

  login(username: string, password: string): Observable<boolean> {
    const headers = new Headers();
    headers.append('Authorization', 'Basic ' + this.b64EncodeUnicode(username + ':' + password));
    headers.append('Content-Type', 'application/json');
    const btoaStr = this.b64EncodeUnicode(username + ':' + password);

    return this.http.post(environment.authLogin, JSON.stringify({
      token: btoaStr,
      username: username
    }), {headers: headers})
      .map((response: Response) => {
        // login successful if there's a jwt token in the response
        const token = response.json() && response.json().token;

        if (token) {
          console.log("Retorno do login no auth service ", response.json());

          // set token property
          this.token = token;
          this.idRH = response.json().idRH;
          this.profName = response.json().profName;
          this.rota = response.json().rota;
          //this.lstGroups = response.json().lstGroups;
          //this.lstEstruturas = response.json().estruturas;
          Cookie.set('W2na8GrHP9ig4766Rxbs', 'loggedin');
          // store username and jwt token in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('currentUser', JSON.stringify({
            username: username,
            token: token,
            idRH: this.idRH,
            profName: this.profName
            //,lstCategoria: this.lstGroups
            //,lstEstruturas:  this.lstEstruturas
          }));
          sessionStorage.setItem('rota', this.rota);
          sessionStorage.setItem('lstCategoria', JSON.stringify(response.json().lstGroups));
          //sessionStorage.setItem('lstEstruturas', JSON.stringify(response.json().lstEstruturas));
          // return true to indicate successful login
          return true;
        } else {
          // return false to indicate failed login
          return false;
        }
      });
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    this.token = null;

    Cookie.delete('W2na8GrHP9ig4766Rxbs');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('rota');
  }
}
