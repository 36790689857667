export class Notification {
  idNotification: number;
  icon: string;
  username: string;
  idRH: number;
  title: string;
  message: string;
  link: string;
  readNotification: boolean;
  active: boolean;
  createUser: string;
  createDate: string;
  modifyUser: string;
  modifyDate: string;
}

export class APICall {
  httpMethod: string;
  apiName: string;
  apiURI: string;
  params: APIParams;
  body: any;
  username: string;
  userToken: string;
}

export class APIParams {
  oiBase: string;
  shortName: string;
  activeProjects: boolean;
  idProject: number;
  structures: string;
  institutions: string;
  financingEntities: string;
  typologyClasses: string;
}
