import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, NoPreloading  } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';

import { FULL_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';

import { AuthGuard } from './shared/auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: './login/login-page.module#LoginPageModule',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './login/login-page.module#LoginPageModule',
    pathMatch: 'full'
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: { title: 'full Views' },
    children: FULL_ROUTES,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: ContentLayoutComponent,
    data: { title: 'content Views' },
    children: CONTENT_ROUTES,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading , onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
