// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  baseAuth : 'https://iris.inesctec.pt/authAPI',
  basePathUrlExport : 'https://iris.inesctec.pt/authAPI/apiRequestFile',
  basePathUrl : 'https://iris.inesctec.pt/authAPI/apiRequest',
  basePathValidateComponentUrl : 'https://iris.inesctec.pt/authAPI/validatecomponent',
  basePathUrlExportByObject : 'https://iris.inesctec.pt/authAPI/apiRequestFileByObject',
  basePathValidateSimpleComponentUrl : 'https://iris.inesctec.pt/authAPI/validateSimplecomponent',
  authLogin : 'https://iris.inesctec.pt/authAPI/login',
  basePathUrlFormData: 'https://iris.inesctec.pt/authAPI/apiRequestFormData',
  basePathUrlFormDataList: 'https://iris.inesctec.pt/apiRequestFormDataList',
  basePathUrlExportByObjectBody: 'https://iris.inesctec.pt/authAPI/apiRequestFileByObjectBody',
  authLoginForm: 'https://intranet.inesctec.pt/login'
};
