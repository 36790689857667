import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {NgModule} from "@angular/core";

//Route for content layout with sidebar, navbar and footer.

export const FULL_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  }
];
