import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../auth/auth.service';
import { Notification } from './../../notification';
import { NotificationService } from "./../../notification.service";
import { Cookie } from 'ng2-cookies/ng2-cookies';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    currentLang = 'en';
    toggleClass = 'ft-maximize';
    notifications: Notification[] = new Array<Notification>();
    notificationsUnread: number = 0;    
    currentUserProfName = decodeURIComponent(Cookie.get('iprofName'));
    constructor(
        public translate: TranslateService
        , private authenticationService: AuthService
        , private notificationService: NotificationService
    ) {
        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
    }


    ChangeLanguage(language: string) {
        this.translate.use(language);
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else {
            this.toggleClass = 'ft-maximize';
        }
    }

    logout(): void {
        this.authenticationService.logout();
    }

    ngOnInit() {
        this.notificationService.currentNotifications.subscribe(notifications => this.notifications = notifications);
        this.notificationService.currentUnreadNotifications.subscribe(notificationsUnread => this.notificationsUnread = notificationsUnread);
    }

    markAllRead() {
        for (var i = 0; i < this.notifications.length; i++) {
            this.notifications[i].readNotification = true;
        }
        this.notificationService.updateNotification(this.notifications);
    }

    markAsRead(notification: Notification) {
        for (var i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i] == notification) {
                this.notifications[i].readNotification = true;
                break;
            }
        }
        this.notificationService.updateNotification(this.notifications);
    }

    markAsUnRead(notification: Notification) {
        for (var i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i] == notification) {
                this.notifications[i].readNotification = false;
                break;
            }
        }
        this.notificationService.updateNotification(this.notifications);
    }

    removeNotification(notification: Notification) {
        for (var i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i] == notification) {
                this.notifications[i].active = false;
                break;
            }
        }
        this.notificationService.inativateNotification(this.notifications);
    }

    removeAllNotifications() {
        for (var i = 0; i < this.notifications.length; i++) {
            this.notifications[i].active = false;
        }
        this.notificationService.inativateNotification(this.notifications);
    }
}

