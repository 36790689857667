import { Component, OnInit, HostListener } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../auth/auth.service';
import { SidebarService } from './sidebar.service';
import { NotificationService } from './../../notification.service';
import { Http, Response, Headers, URLSearchParams, RequestOptions } from '@angular/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  //private currentUser = JSON.parse(sessionStorage.getItem('currentUser')).username;
  //private currentToken = JSON.parse(sessionStorage.getItem('currentUser')).token;
  private currentUser = Cookie.get('username');
  private currentToken = Cookie.get('itoken');
  private result;
  private ar: string[];
  private currentUserCategorias;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private side: SidebarService,
    private notificationService: NotificationService,
    private http: Http) {

    try {
      if (Cookie.get('username')) {
        this.currentUser = Cookie.get('username');
        window.location.href = 'https://intranet.inesctec.pt/logout';
      } else {

        this.currentUser = Cookie.get('iuser');

      }
    } catch (error) {
      this.currentUser = Cookie.get('iuser');
    }
/*
    if (Cookie.get('iidRH') != '') {
      this.side.getUserData(this.currentUser, this.currentToken).then(data => {
        let datas = JSON.parse(data);
        //console.log("Retorno do getUserData no sidebar component ", datas);

        if (encodeURIComponent(datas.estruturas) != "") {
          //Cookie.set('lstEstruturas', encodeURIComponent(datas.estruturas));
        }

        if (encodeURIComponent(datas.profName) != "") {
          Cookie.set('iprofName', encodeURIComponent(datas.profName));
        }

        if (Cookie.get('ilstCategoria') != "") {
          Cookie.set('lstCategoria', encodeURIComponent(datas.lstGroups));  
          Cookie.set('idRH', datas.idRH);       
          //Cookie.set('lstCargos', encodeURIComponent(datas.lstGroups));          
        } 
      });
    }*/

    this.ar = new Array();

    for (let i = 0; i < ROUTES.length; i++) {
      this.ar.push(ROUTES[i].path);
      for (let j = 0; j < ROUTES[i].submenu.length; j++) {

        this.ar.push(ROUTES[i].submenu[j].path);

      }
    }
    //console.log(this.ar);
    this.notificationService.getAllNotifications();

    this.side.getPermissionsRoutes(this.currentUser, this.currentToken, this.ar).then(data => {
      let userMenu = [];
      //sessionStorage.setItem('rota', data);

      userMenu = JSON.parse(data);

      $.getScript('./assets/js/app-sidebar.js');

      this.menuItems = ROUTES.filter(menuItem => userMenu.indexOf(menuItem.path) !== -1);

      for (let i = 0; i < this.menuItems.length; i++) {
        if (this.menuItems[i].submenu.length > 0) {
          this.menuItems[i].submenu = this.menuItems[i].submenu.filter(menuItem => userMenu.indexOf(menuItem.path) !== -1);

        }
      }
    });

  }

  ngOnInit() {

  }


  //NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf('forms/ngx') !== -1) {
      this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
    }
  }
}
