import { Injectable } from '@angular/core';
import { Http, Response, Headers, URLSearchParams, RequestOptions } from '@angular/http';

import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/toPromise';
import { resolve } from 'core-js/fn/promise';
import { environment } from './../../../environments/environment';

@Injectable()
export class SidebarService {
  private basePathUrl = environment.baseAuth;



  private validateToken = this.basePathUrl + '/validatesession';
  public validateRoute = this.basePathUrl + '/validateroute';
  public validateRouteWithParameters = this.basePathUrl + '/validaterouteswithparameters';
  public validateRoutes = this.basePathUrl + '/validateroutes';
  public getPersonalData = this.basePathUrl + '/getPersonalData';

  public validateRouteFrontBackUrl = this.basePathUrl + 'validateRouteFrontBack';

  constructor(private http: Http) {}

   /**
   * Este metodo é utilizado para a validação
   *
   * @param usern
   * @param token
   * @param routes
   */
    getUserData(usern: string, token: string): Promise<string> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    const options = new RequestOptions({ headers: headers });
    return this.http
      .post(this.getPersonalData, '{"username": "' + usern + '", "token": "' + token + '"}', options)
      .map(this.extractRoutes).toPromise();
  }

  /**
   * Este metodo é utilizado para a validação dos menues
   *
   * @param usern
   * @param token
   * @param routes
   */

  getPermissionsRoutes(usern: string, token: string, routes: Array<any>): Promise<string> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    const options = new RequestOptions({ headers: headers });
    return this.http
      .post(this.validateRoutes, '{"username": "' + usern + '", "token": "' + token + '","route":"' + routes + '"}', options)
      .map(this.extractRoutes).toPromise();
  }

      getPermissionsNewWithParameters(usern: string, token: string, url: string): Promise<string> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    const options = new RequestOptions({ headers: headers });
    return this.http
      .post(this.validateRouteWithParameters, '{"username": "' + usern + '", "token": "' + token + '","route":"'+url+'"}', options)
      .map(this.extractRoutes).toPromise();
  }


  /**
   *
   * Utilizado para a validaçao se a rota é valida. é utilizado no auth-guard.service.ts -  canActivate()
   *
   */
  getPermissionsNew(usern: string, token: string, url: string): Promise<boolean> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    const options = new RequestOptions({ headers: headers });
    return this.http
      .post(this.validateRoute, '{"username": "' + usern + '", "token": "' + token + '","route":"'+url+'"}', options)
      .map(this.extractGroups).toPromise();
  }

  private handleError(error: Response | any): Observable<boolean> {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.err || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    console.error(errMsg);
    return Observable.throw(errMsg);
  }

  /**
   * Metodo utilizado para a validaçao do token.
   * @param usern
   * @param token
   */
  getValidateToken(usern: string, token: string): Promise<string> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    const options = new RequestOptions({ headers: headers });
    return this.http
      .post(this.validateToken, '{"username": "' + usern + '", "token": "' + token + '"}', options)
      .map(this.extractGroups).toPromise();
  }


   /**
   * Metodo utilizado para a validaçao do Component.
   * @param usern
   * @param token
   */
  getValidateComponent(usern: string, token: string): Promise<string> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    const options = new RequestOptions({ headers: headers });
    return this.http
      .post(this.validateToken, '{"username": "' + usern + '", "token": "' + token + '"}', options)
      .map(this.extractGroups).toPromise();
  }

  private extractGroups(res: Response) {
    const body = JSON.stringify(res.json());

    var d;

    if (body == "true") {
      d = true;
    } else {
      d = false;
    }
     return d;
  }

  private extractRoutes(res: Response) {
    const body = JSON.stringify(res.json());
    //console.log("Retorno do extractRoutes no sidebar.service ", body);
    return body;
  }

     /**
   *
   * Utilizado para validar se o usuário tem permissão para execução da rota
   * Deverá ser executado no construtor de cada página evitando
   * redirecionamentos de páginas sem permissão.
   * Deverá também ser executado antes de cada chamada de método
   * para validação de permissão do usuário ao método (para além da página)
   * Criado por: Thiago Andrade em 28/09/2020
   */
  validateRouteFrontBack(usern: string, token: string, url: string) : Promise<any>{

    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');

    const options = new RequestOptions({ headers: headers });
    const payload = '{"username": "' + usern + '", "token": "' + token + '","route":"' + url + '"}';
    return this.http
      .post(this.validateRouteFrontBackUrl, payload, options)
      .toPromise();

  }

}
